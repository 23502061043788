import React from 'react'

const HomePage = () => {
  return (
    <div className="page">
      <div className="text-xl">Home Page</div>
    </div>
  )
}

export default HomePage
