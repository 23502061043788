// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mc-button {
  display: flex;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  align-items: center;
  justify-content: center;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}.mc-button:active {
  --tw-scale-x: 0.98;
  --tw-scale-y: 0.98;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}.mc-button {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */ /* Internet Explorer/Edge */
  user-select: none;
}

.mc-button[data-color='primary'][data-variant='contained'] {
  --tw-bg-opacity: 1;
  background-color: rgb(88 55 143 / var(--tw-bg-opacity));
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.mc-button[data-color='primary'][data-variant='contained']:active {
  --tw-bg-opacity: 1;
  background-color: rgb(74 46 119 / var(--tw-bg-opacity));
}

.mc-button[data-color='primary'][data-variant='text'] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(88 55 143 / var(--tw-text-opacity));
}

.mc-button[data-color='primary'][data-variant='text']:active {
  --tw-bg-opacity: 1;
  background-color: rgb(241 234 252 / var(--tw-bg-opacity));
}

.mc-button[data-color='primary'][data-variant='outlined'] {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(88 55 143 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(88 55 143 / var(--tw-text-opacity));
}

.mc-button[data-color='primary'][data-variant='outlined']:active {
  --tw-bg-opacity: 1;
  background-color: rgb(241 234 252 / var(--tw-bg-opacity));
}
`, "",{"version":3,"sources":["webpack://./src/app/components/common/atoms/Button/Button.css"],"names":[],"mappings":"AACE;EAAA,aAA6F;EAA7F,+LAA6F;EAA7F,mBAA6F;EAA7F,uBAA6F;EAA7F,gKAA6F;EAA7F,wJAA6F;EAA7F,iLAA6F;EAA7F,wDAA6F;EAA7F;AAA6F,CAA7F;EAAA,kBAA6F;EAA7F,kBAA6F;EAA7F;AAA6F,CAD/F;EAEE,yBAAyB,EAAE,WAAW;EACtC,sBAAsB,EAAE,YAAY,EACb,2BAA2B;EAClD,iBAAiB;AACnB;;AAGE;EAAA,kBAA0E;EAA1E,uDAA0E;EAA1E,eAA0E;EAA1E,mBAA0E;EAA1E,gBAA0E;EAA1E,oBAA0E;EAA1E;AAA0E;;AAA1E;EAAA,kBAA0E;EAA1E;AAA0E;;AAI1E;EAAA,kBAA4E;EAA5E,yDAA4E;EAA5E,eAA4E;EAA5E,mBAA4E;EAA5E,gBAA4E;EAA5E,oBAA4E;EAA5E;AAA4E;;AAA5E;EAAA,kBAA4E;EAA5E;AAA4E;;AAI5E;EAAA,iBAAoG;EAApG,sBAAoG;EAApG,uDAAoG;EAApG,kBAAoG;EAApG,yDAAoG;EAApG,eAAoG;EAApG,mBAAoG;EAApG,gBAAoG;EAApG,oBAAoG;EAApG;AAAoG;;AAApG;EAAA,kBAAoG;EAApG;AAAoG","sourcesContent":[".mc-button {\n  @apply flex items-center justify-center active:scale-[0.98] transition transform duration-150;\n  -webkit-user-select: none; /* Safari */\n  -moz-user-select: none; /* Firefox */\n  -ms-user-select: none; /* Internet Explorer/Edge */\n  user-select: none;\n}\n\n.mc-button[data-color='primary'][data-variant='contained'] {\n  @apply bg-[#58378F] active:bg-[#4A2E77] text-white font-semibold text-base;\n}\n\n.mc-button[data-color='primary'][data-variant='text'] {\n  @apply bg-[#FFFFFF] active:bg-[#F1EAFC] text-[#58378F] font-medium text-base;\n}\n\n.mc-button[data-color='primary'][data-variant='outlined'] {\n  @apply bg-[#FFFFFF] active:bg-[#F1EAFC] text-[#58378F] border border-[#58378F] font-medium text-base;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
